import React, { memo } from 'react'
import { useRecording } from '../../context/RecordingContext'

const SummaryTabs = memo(() => {
  const { state, dispatch } = useRecording()

  const tabs = {
    'General Summary': state.results.general,
    'Meeting Minutes': state.results.minutes,
    'Simple Summary': state.results.simple,
  }

  return (
    <div className='border-b border-gray-200 mb-4 w-full'>
      <div className='flex space-x-4'>
        {Object.keys(tabs).map((tab) => (
          <button
            key={tab}
            onClick={() => dispatch({ type: 'SET_ACTIVE_TAB', payload: tab })}
            className={`text-xs sm:text-sm px-1 py-2 text-gray-600 font-medium border-b-2 ${
              state.activeTab === tab
                ? 'border-[#2980b9] text-[#2980b9]'
                : 'border-transparent hover:text-[#2980b9]'
            }`}
          >
            {tab}
          </button>
        ))}
      </div>
      <SummaryDisplay content={tabs[state.activeTab]} />
    </div>
  )
})

export default SummaryTabs

const SummaryDisplay = ({ content }) => {
  const parts = content.split(/(\*\*.*?\*\*)/g)

  return (
    <div className='bg-gray-100 p-2 rounded-lg min-h-[150px] mt-4 whitespace-pre-wrap'>
      {parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          // It's a header - remove the ** and make it bold
          return <b key={index}>{part.slice(2, -2)}</b>
        }
        // Regular text
        return <span key={index}>{part}</span>
      })}
    </div>
  )
}
