import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import PageLayout from '../components/PageLayout'
import { RecordingProvider, useRecording } from '../context/RecordingContext'
import { useMediaRecorder } from '../hooks/useMediaRecorder'
import { useAudioProcessor } from '../hooks/useAudioProcessor'
import RecordingTimer from '../components/Recording/RecordingTimer'
import RecordingControls from '../components/Recording/RecordingControls'
import SummaryTabs from '../components/Recording/SummaryTabs'
import Modal from '../components/Modal'
import {
  AiOutlineCopy,
  AiOutlineFilePdf,
  AiOutlineFileWord,
  AiOutlineMail,
} from 'react-icons/ai'
import jsPDF from 'jspdf'
import { Document, Packer, Paragraph } from 'docx'
import { saveAs } from 'file-saver'
import LoadingSpinner from '../components/LoadingSpinner'
import useAuth from '../hooks/useAuth'
import LanguageSelector from '../components/Recording/LanguageSelector'
import { allLanguages } from '../utils/constants'

const RecordingPage = () => {
  return (
    <RecordingProvider>
      <RecordingPageContent />
    </RecordingProvider>
  )
}

const RecordingPageContent = () => {
  const { user } = useAuth()

  const { state, dispatch } = useRecording()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [limitStats, setLimitStats] = useState({
    allowedLimit: 0,
    currentPeriodUsage: 0,
    remainingTime: 0,
  })

  const { startRecording, stopRecording } = useMediaRecorder({ limitStats })
  const processAudio = useAudioProcessor()

  const fetchLimits = useCallback(async () => {
    setIsPageLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usage`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      const data = await response.data
      const { currentPeriodUsage, allowedLimit } = data.data

      setLimitStats({
        allowedLimit,
        currentPeriodUsage: Math.ceil(Number(currentPeriodUsage)),
        remainingTime: Math.ceil(
          Number(allowedLimit) - Number(currentPeriodUsage)
        ),
      })
      setIsPageLoading(false)
    } catch (error) {
      setLimitStats({
        allowedLimit: 0,
        currentPeriodUsage: 0,
        remainingTime: 0,
      })
      setIsPageLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchLimits()

    // eslint-disable-next-line
  }, [])

  const saveAndGenerateTranscript = async () => {
    await saveRecording(state.recordedBlob)
    dispatch({ type: 'SET_IS_TRANSCRIBING' })
    dispatch({ type: 'SET_MODAL', payload: false })
    await handleTranscription(state.recordedBlob)
  }

  const saveRecording = async (blob) => {
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-')
    const defaultFileName = `recording_${timestamp}.wav`

    try {
      if ('showSaveFilePicker' in window) {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: defaultFileName,
          types: [
            {
              description: 'WAV Audio File',
              accept: { 'audio/wav': ['.wav'] },
            },
          ],
        })

        const writable = await fileHandle.createWritable()
        await writable.write(blob)

        await writable.close()
      } else {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = defaultFileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        console.log('Recording download prompt shown (fallback method)')
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.warn('User aborted the save operation')
      } else {
        console.error('Error saving the file:', err)
        alert('An error occurred while saving the file. Please try again.')
      }
    }
  }

  const handleTranscription = async (blob) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const audioBlob = await processAudio(blob)
      const formData = new FormData()
      formData.append('file', audioBlob)
      formData.append('source', state.selectedLanguages.source)
      formData.append('target', state.selectedLanguages.target)

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audio/transcribe_recording`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      dispatch({
        type: 'SET_RESULTS',
        payload: {
          general: response.data.data.generalSummary,
          minutes: response.data.data.meetingMinutes,
          simple: response.data.data.simpleSummary,
        },
      })
      dispatch({
        type: 'SET_TRANSCRIPTION_STATUS',
        payload: { type: 'success' },
      })
    } catch (error) {
      dispatch({
        type: 'SET_TRANSCRIPTION_STATUS',
        payload: {
          type: 'error',
          message:
            "Your transcription request has being processed. Please check your email within 5 minutes for the results. If you don't receive an email, please use the upload section to try again.",
        },
      })
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleCancel = () => {
    setShowCancelModal(true)
  }

  const confirmCancel = () => {
    dispatch({ type: 'SET_MODAL', payload: false })
    dispatch({ type: 'RESTART' })
    dispatch({ type: 'UPDATE_TIME' })
    setShowCancelModal(false)
  }

  const cancelCancel = () => {
    setShowCancelModal(false)
  }

  const exportAsWord = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({ text: 'General Summary' }),
            new Paragraph({ text: state.results.general }),
            new Paragraph({ text: 'Meeting Minutes' }),
            new Paragraph({ text: state.results.minutes }),
            new Paragraph({ text: 'Simple Summary' }),
            new Paragraph({ text: state.results.simple }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'meeting_summary.docx')
    })
  }

  const exportAsPDF = () => {
    const doc = new jsPDF()
    doc.text('General Summary', 10, 10)
    doc.text(state.results.general, 10, 20)
    doc.text('Meeting Minutes', 10, 100)
    doc.text(state.results.minutes, 10, 110)
    doc.text('Simple Summary', 10, 200)
    doc.text(state.results.simple, 10, 210)
    doc.save('meeting_summary.pdf')
  }

  const sendEmail = () => {
    const subject = encodeURIComponent('Meeting Summary')
    const body = encodeURIComponent(
      `General Summary:\n${state.results.general}\n\nMeeting Minutes:\n${state.results.minutes}\n\nSimple Summary:\n${state.results.simple}`
    )
    window.location.href = `mailto:?subject=${subject}&body=${body}`
  }

  const copyToClipboard = () => {
    const text = `General Summary:\n${state.results.general}\n\nMeeting Minutes:\n${state.results.minutes}\n\nSimple Summary:\n${state.results.simple}`
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!')
    })
  }

  if (isPageLoading) {
    return (
      <main className='py-20 flex items-center justify-center'>
        <div className='loading-spinner'></div>
      </main>
    )
  }

  return (
    <>
      <PageLayout userName={user?.name}>
        <div className='mx-auto my-8'>
          {!state.isFinishedRecording ? (
            <div className='flex flex-col justify-center gap-4'>
              {!state.isRecording ? (
                <div className='bg-white rounded-lg shadow-md p-6'>
                  <h1 className='text-2xl font-bold text-gray-800 mb-6 text-center'>
                    Meeting Recorder
                  </h1>

                  <LanguageSelector
                    dispatch={dispatch}
                    selectedLanguages={state.selectedLanguages}
                  />

                  <div className='mt-8 flex justify-center'>
                    {!state.isRecording && (
                      <button
                        onClick={startRecording}
                        className='bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg
                          transition-colors duration-200 flex items-center space-x-2'
                      >
                        <span>Start Recording</span>
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <h3 className='text-sm text-center'>
                    Your summary will be generated in{' '}
                    {
                      allLanguages.find(
                        (lang) => lang.code === state.selectedLanguages.target
                      )?.name
                    }{' '}
                  </h3>
                  <RecordingTimer
                    limitStats={limitStats}
                    stopRecording={stopRecording}
                  />
                  <RecordingControls onStop={stopRecording} />
                </>
              )}

              <div className='bg-blue-50 border-l-4 border-blue-600 p-4 mb-4 rounded-r-lg'>
                <h3 className='mt-0 font-semibold'>Recording Details</h3>
                <ul className='mb-0 text-gray-700 list-disc list-inside text-sm sm:text-base'>
                  <li>
                    Your recording will be automatically transcribed and emailed
                    to you
                  </li>
                  <li>Recording quality is optimized for voice clarity</li>
                  <li>System notifications are excluded from the recording</li>
                  <li>You can pause the recording at any time</li>
                </ul>
              </div>

              <div className='bg-orange-50 border-l-4 border-orange-600 p-4 mb-4 rounded-r-lg'>
                <h3 className='mt-0 font-semibold'>Important Notes</h3>
                <ul className='mb-0 text-gray-700 list-disc list-inside text-sm sm:text-base'>
                  <li>
                    Do not close or refresh this tab - it will stop the
                    recording
                  </li>
                  <li>
                    Recording will automatically stop when your time limit is
                    reached
                  </li>
                  <li>Make sure you have a stable internet connection</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className='mx-auto'>
              {state.isLoading ? (
                <div className='text-center'>
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {state.summaryStatus?.type && (
                    <div
                      className={`${
                        state.summaryStatus.type === 'error'
                          ? 'bg-blue-50 text-blue-700 border border-blue-200'
                          : 'bg-green-100  text-green-700'
                      } text-sm sm:text-base p-4 rounded-lg mb-4`}
                    >
                      {state.summaryStatus?.type === 'error'
                        ? state.summaryStatus.message
                        : 'Transcription completed successfully! You can now view and export your summaries.'}
                    </div>
                  )}
                  {state.summaryStatus?.type !== 'error' && (
                    <>
                      {' '}
                      <SummaryTabs />{' '}
                      <div className='flex justify-center space-x-4 my-8'>
                        <button
                          onClick={exportAsWord}
                          className='bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600'
                          title='Export as Word'
                        >
                          <AiOutlineFileWord size={24} />
                        </button>
                        <button
                          onClick={exportAsPDF}
                          className='bg-red-500 text-white p-2 rounded-lg hover:bg-red-600'
                          title='Export as PDF'
                        >
                          <AiOutlineFilePdf size={24} />
                        </button>
                        <button
                          onClick={sendEmail}
                          className='bg-orange-500 text-white p-2 rounded-lg hover:bg-orange-600'
                          title='Email'
                        >
                          <AiOutlineMail size={24} />
                        </button>
                        <button
                          onClick={copyToClipboard}
                          className='bg-green-500 text-white p-2 rounded-lg hover:bg-green-600'
                          title='Copy'
                        >
                          <AiOutlineCopy size={24} />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </PageLayout>
      {state.showModal && (
        <Modal isOpen={state.showModal}>
          <div className='mt-6 w-full max-w-lg p-6 rounded-lg'>
            {/* {recordExceeded && (
              <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 rounded mb-4'>
                You have reached your recording limit.
              </div>
            )} */}
            <p className='text-center font-medium text-sm'>
              Please save your recording now.
            </p>
            <div className='flex flex-col justify-center mt-4 gap-4'>
              <button
                onClick={saveAndGenerateTranscript} // New function to save, transcribe, and email
                className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600'
              >
                Save Recording, Transcribe, and Email Summary
              </button>

              <button
                onClick={handleCancel}
                className='bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600'
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showCancelModal && (
        <Modal isOpen={showCancelModal}>
          <div className='mt-6 w-full max-w-lg p-6 rounded-lg'>
            <p className='text-center font-medium text-sm'>
              If you cancel now, your recorded audio will be lost. Are you sure
              you want to cancel?
            </p>
            <div className='flex flex-col justify-center mt-4 gap-4'>
              <button
                onClick={confirmCancel}
                className='bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600'
              >
                Yes, Cancel and Discard Recording
              </button>
              <button
                onClick={cancelCancel}
                className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600'
              >
                No, Keep Recording
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default RecordingPage
