import React, { createContext, useContext, useReducer } from 'react'

const RecordingContext = createContext()

const initialState = {
  stage: 'initial',
  isRecording: false,
  selectedLanguages: { source: 'en', target: 'en' },
  isPaused: false,
  recordingTime: 0,
  recordedBlob: null,
  showActions: false,
  isLoading: false,
  showModal: false,
  isFinishedRecording: false,
  summaryStatus: { type: '', message: '' },
  results: {
    general: '',
    minutes: '',
    simple: '',
  },
  activeTab: 'General Summary',
}

const recordingReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_LANGUAGES':
      if (action.payload.type === 'source') {
        return {
          ...state,
          selectedLanguages: {
            ...state.selectedLanguages,
            source: action.payload.language,
          },
        }
      } else {
        return {
          ...state,
          selectedLanguages: {
            ...state.selectedLanguages,
            target: action.payload.language,
          },
        }
      }
    case 'START_RECORDING':
      return { ...state, isRecording: true, stage: 'recording' }
    case 'STOP_RECORDING':
      return {
        ...state,
        isRecording: false,
        isPaused: false,
        recordedBlob: action.payload,
      }
    case 'PAUSE_RECORDING':
      return { ...state, isPaused: true }
    case 'RESUME_RECORDING':
      return { ...state, isPaused: false }
    case 'UPDATE_TIME':
      return {
        ...state,
        recordingTime: state.recordingTime + 1,
      }
    case 'SET_RESULTS':
      return {
        ...state,
        results: action.payload,
      }
    case 'SET_IS_TRANSCRIBING':
      return {
        ...state,
        isFinishedRecording: true,
      }

    case 'SET_TRANSCRIPTION_STATUS':
      return {
        ...state,
        summaryStatus: action.payload,
      }

    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.payload }
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'SET_MODAL':
      return { ...state, showModal: action.payload }
    case 'RESTART':
      return initialState
    default:
      return state
  }
}

export const RecordingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(recordingReducer, initialState)

  return (
    <RecordingContext.Provider value={{ state, dispatch }}>
      {children}
    </RecordingContext.Provider>
  )
}

export const useRecording = () => {
  const context = useContext(RecordingContext)
  if (!context) {
    throw new Error('useRecording must be used within a RecordingProvider')
  }
  return context
}
