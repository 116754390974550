// components/RecordingControls.js
import React, { memo } from 'react'

import { FaPause, FaPlay, FaStop } from 'react-icons/fa6'
import { useRecording } from '../../context/RecordingContext'

const RecordingControls = memo(({ onStop }) => {
  const { state, dispatch } = useRecording()

  const handlePause = () => {
    dispatch({ type: 'PAUSE_RECORDING' })
  }

  const handleResume = () => {
    dispatch({ type: 'RESUME_RECORDING' })
  }

  return (
    <div className='flex justify-center gap-4 mt-8'>
      {!state.isPaused ? (
        <button
          onClick={handlePause}
          className='bg-orange-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-orange-500 transition-transform transform hover:translate-y-[-1px]'
        >
          <FaPause />
          Pause
        </button>
      ) : (
        <button
          onClick={handleResume}
          className='bg-green-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-green-500 transition-transform transform hover:translate-y-[-1px]'
        >
          <FaPlay />
          Resume
        </button>
      )}
      <button
        onClick={onStop}
        className='bg-red-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-red-500 transition-transform transform hover:translate-y-[-1px]'
      >
        <FaStop />
        Stop Recording
      </button>
    </div>
  )
})

export default RecordingControls
