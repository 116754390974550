import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const VerifyPhone = () => {
  const [phone, setPhone] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [preferredChannel, setPreferredChannel] = useState('sms');
  const [isAgreed, setIsAgreed] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showOtpSection, setShowOtpSection] = useState(false);
  const { user, login } = useAuth();
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleInitialSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_BASE_URL}/api/auth/verify-phone`, {
        phone,
        preferredChannel,
        userId: user?.id,
      });

      if (response && response.data) {
        setMessage(response.data.message);
        setShowConfirmation(false);
        setShowOtpSection(true);
      } else {
        setError('Unexpected response from the server.');
      }
    } catch (err) {
      console.error('Error sending verification code:', err);
      setError(err.response?.data?.message || 'Failed to send verification code.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setShowConfirmation(false);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setShowConfirmation(false);
    setShowOtpSection(false);
    setError('');
    setMessage('');
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/verify-phone-otp`, {
        otp: otp.join(''),
        userId: user?.id,
      });

      if (response && response.data) {
        setMessage(response.data.message);
        login(response.data);
        navigate('/dashboard');
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError(err.response?.data?.message || 'Failed to verify OTP.');
    }
  };

  // Your existing navigation guards
  if (!user?.id) return <Navigate to='/register' />;
  if (user?.id && !user?.isEmailVerified) return <Navigate to='/verify-email' />;
  if (user?.id && user?.isPhoneVerified) return <Navigate to='/dashboard' />;

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center px-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 space-y-6">
        <h1 className="text-2xl font-bold text-center text-gray-800">
          Verify Your Phone Number
        </h1>

        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-md text-sm">
            {error}
          </div>
        )}

        {message && (
          <div className="bg-green-50 text-green-600 p-4 rounded-md text-sm">
            {message}
          </div>
        )}

        <form onSubmit={handleInitialSubmit} className="space-y-6">
          <div className="space-y-2">
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={handlePhoneChange}
              inputClass="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              containerClass="w-full"
              inputStyle={{ fontSize: '16px', height: '45px' }}
              preferredCountries={['ca', 'us', 'ng', 'gb', 'de']}
              enableSearch={true}
              searchPlaceholder="Search your country"
            />
          </div>

          {showConfirmation && (
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 space-y-4">
              <p className="text-sm text-blue-800">Is this your phone number?</p>
              <div className="font-medium text-lg text-blue-900">+{phone}</div>
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={handleEdit}
                  className="px-4 py-2 text-sm border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
                >
                  Edit
                </button>
                <button
                  type="button"
                  onClick={handleConfirm}
                  disabled={isLoading}
                  className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50"
                >
                  {isLoading ? (
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    'Yes, continue'
                  )}
                </button>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div className="space-y-2">
              <p className="font-semibold text-gray-700">Receive verification code via:</p>
              <label className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md cursor-pointer hover:bg-blue-50 transition-colors">
                <input
                  type="radio"
                  name="preferredChannel"
                  value="sms"
                  checked={preferredChannel === 'sms'}
                  onChange={() => setPreferredChannel('sms')}
                  className="cursor-pointer"
                />
                <span className="text-gray-600">SMS</span>
              </label>
            </div>

            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                className="rounded border-gray-300"
              />
              <span className="text-sm text-gray-600">
                I agree to receive text messages from Reekap
              </span>
            </label>

            {!showConfirmation && !showOtpSection && (
              <button
                type="submit"
                disabled={!phone || !isAgreed || isLoading}
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Continue
              </button>
            )}
          </div>
        </form>

        {showOtpSection && (
          <div className="space-y-6">
            <p className="text-center text-gray-600">
              Enter the verification code sent to +{phone}
            </p>
            <form onSubmit={handleOtpSubmit} className="space-y-4">
              <div className="flex justify-center space-x-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => {
                      const newOtp = [...otp];
                      newOtp[index] = e.target.value;
                      setOtp(newOtp);
                      if (e.target.value && index < 5) {
                        e.target.nextSibling?.focus();
                      }
                    }}
                    className="w-12 h-12 text-center text-xl font-semibold border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                ))}
              </div>
              <button
                type="submit"
                disabled={isLoading || otp.join('').length !== 6}
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent"></div>
                  </div>
                ) : (
                  'Verify Code'
                )}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyPhone;
