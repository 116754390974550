import { useCallback, useRef, useEffect } from 'react'

const workerCode = `
  const writeString = (view, offset, string) => {
    const len = string.length;
    const arr = new Uint8Array(view.buffer, offset, len);
    for (let i = 0; i < len; i++) arr[i] = string.charCodeAt(i);
  };

  const floatTo16BitPCM = (view, offset, buffer) => {
    const channelData = buffer.channelData;
    const numChannels = channelData.length;
    const len = buffer.length;
    
    for (let i = 0; i < len; i++) {
      for (let channel = 0; channel < numChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, channelData[channel][i]));
        view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }
  };

  const convertToWav = (audioData) => {
    const numChannels = audioData.channelData.length;
    const length = audioData.length * numChannels * 2;
    const buffer = new ArrayBuffer(44 + length);
    const view = new DataView(buffer);
    const sampleRate = Math.min(48000, audioData.sampleRate);

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numChannels * 2, true);
    view.setUint16(32, numChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    floatTo16BitPCM(view, 44, audioData);
    return buffer;
  };

  self.onmessage = ({ data }) => {
    if (data.type === 'convert') {
      try {
        const wavData = convertToWav(data.audioData);
        self.postMessage({ type: 'complete', data: wavData }, [wavData]);
      } catch (error) {
        self.postMessage({ type: 'error', error: error.message });
      }
    }
  };
`

export const useAudioProcessor = () => {
  const audioContextRef = useRef(null)
  const workerUrlRef = useRef(null)

  useEffect(() => {
    // Create worker URL once when component mounts
    workerUrlRef.current = URL.createObjectURL(
      new Blob([workerCode], { type: 'application/javascript' })
    )

    return () => {
      // Cleanup when component unmounts
      if (audioContextRef.current) {
        audioContextRef.current.close()
      }
      if (workerUrlRef.current) {
        URL.revokeObjectURL(workerUrlRef.current)
      }
    }
  }, [])

  const processAudioData = useCallback(async (audioData) => {
    try {
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)({
          latencyHint: 'playback',
        })
      }

      const buffer = await audioContextRef.current.decodeAudioData(audioData)
      const offlineContext = new OfflineAudioContext(
        buffer.numberOfChannels,
        buffer.length,
        buffer.sampleRate
      )

      const source = offlineContext.createBufferSource()
      source.buffer = buffer
      source.connect(offlineContext.destination)
      source.start()

      const renderedBuffer = await offlineContext.startRendering()
      const channelData = Array.from(
        { length: renderedBuffer.numberOfChannels },
        (_, i) => renderedBuffer.getChannelData(i)
      )

      return {
        channelData,
        length: renderedBuffer.length,
        sampleRate: renderedBuffer.sampleRate,
      }
    } catch (error) {
      throw new Error('Audio processing failed: ' + error.message)
    }
  }, [])

  const processAudio = useCallback(
    async (blob) => {
      if (!workerUrlRef.current) {
        throw new Error('Worker URL not initialized')
      }

      return new Promise((resolve, reject) => {
        const worker = new Worker(workerUrlRef.current)

        worker.onmessage = ({ data }) => {
          if (data.type === 'complete') {
            resolve(new Blob([data.data], { type: 'audio/wav' }))
            worker.terminate()
          } else if (data.type === 'error') {
            reject(new Error(data.error))
            worker.terminate()
          }
        }

        worker.onerror = (error) => {
          reject(new Error('Worker error: ' + error.message))
          worker.terminate()
        }

        const reader = new FileReader()
        reader.onload = async () => {
          try {
            const audioInfo = await processAudioData(reader.result)
            worker.postMessage({ type: 'convert', audioData: audioInfo })
          } catch (error) {
            reject(error)
            worker.terminate()
          }
        }
        reader.onerror = () => reject(new Error('Failed to read audio data'))
        reader.readAsArrayBuffer(blob)
      })
    },
    [processAudioData]
  )

  return processAudio
}
