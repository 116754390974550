import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { LuAlertCircle, LuFileAudio, LuCheckCircle } from 'react-icons/lu'
import axios from 'axios'
import {
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineMail,
  AiOutlineCopy,
} from 'react-icons/ai'
import { toast } from 'sonner'
import { jsPDF } from 'jspdf'
import { Document, Packer, Paragraph } from 'docx'
import { saveAs } from 'file-saver'
import { getAudioDuration } from '../utils/secondsToMinutes'
import { useAudioProcessor } from '../hooks/useAudioProcessor'
import useAuth from '../hooks/useAuth'
import PageLayout from '../components/PageLayout'
import LoadingSpinner from '../components/LoadingSpinner'

import UploadSummaryTabs from '../components/Uploading/UploadSummaryTabs'
import UploadLanguageSelector from '../components/Uploading/UploadLanguageSelector'

const UploadAudio = () => {
  const processAudio = useAudioProcessor()
  const { user } = useAuth()

  const [isTranscribing, setIsTranscribing] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [status, setStatus] = useState({ type: '', message: '' })
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  const [limitStats, setLimitStats] = useState({
    allowedLimit: 0,
    currentPeriodUsage: 0,
    remainingTime: 0,
  })
  const [results, setResults] = useState({
    general: '',
    minutes: '',
    simple: '',
  })

  const [summariesGenerated, setSummariesGenerated] = useState(false)

  const handleFile = async (file) => {
    setStatus({ type: '', message: '' })

    // Validate file type
    const validTypes = [
      'audio/mp3',
      'audio/wav',
      'audio/ogg',
      'audio/m4a',
      'audio/aac',
      'audio/x-m4a',
      'audio/mpeg',
      'audio/flac',
      'audio/x-flac',
      'audio/aiff',
      'audio/x-aiff',
      'audio/alac',
      'audio/webm',
      'audio/opus',
      'audio/x-wav',
      'audio/vnd.wave',
      'audio/x-pn-wav',
      'audio/vnd.rn-realaudio',
      'audio/x-realaudio',
      'audio/x-ms-wma',
    ]
    if (!validTypes.includes(file.type)) {
      toast.error('Please upload a valid audio file (MP3, WAV, OGG)')
      return
    }

    const MAX_FILE_SIZE = 500 * 1024 * 1024 // 500MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      toast.error('File size must be less than 500MB')
      return
    }

    try {
      setIsTranscribing(true)
      const duration = await getAudioDuration(file)
      if (Number(duration) > limitStats.remainingTime) {
        toast.error(
          `Audio duration (${Math.ceil(
            duration
          )} minutes) exceeds available minutes (${
            limitStats.remainingTime
          } minutes)`
        )
        setIsTranscribing(false)
        return
      }

      const audioBlob = await processAudio(file)
      const formData = new FormData()
      formData.append('file', audioBlob)
      formData.append('target', selectedLanguage)

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audio/transcribe_recording`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      setResults({
        general: response.data.data.generalSummary,
        minutes: response.data.data.meetingMinutes,
        simple: response.data.data.simpleSummary,
      })
      setSummariesGenerated(true)
      setStatus({ type: 'success' })
      toast.success('Transcription completed successfully!')
    } catch (error) {
      const errorMessage =
        // error.response?.data?.message ||
        // 'Failed to upload file. Please try again.'

        "Your transcription request has being processed. Please check your email within 5 minutes for the results. If you don't receive an email, please try again later."
      setStatus({
        type: 'error',
        message: errorMessage,
      })
      toast.error(errorMessage)
    } finally {
      setIsTranscribing(false)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFile(acceptedFiles[0])
      }
    },
    accept: {
      'audio/*': [
        '.mp3',
        '.wav',
        '.ogg',
        '.m4a',
        '.aac',
        '.wma',
        '.flac',
        '.aiff',
        '.alac',
        '.webm',
        '.opus',
      ],
    },
    disabled: isTranscribing || limitStats.remainingTime < 1,
    multiple: false,
  })

  const fetchLimits = useCallback(async () => {
    setIsPageLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usage`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      const data = await response.data
      const { currentPeriodUsage, allowedLimit } = data.data

      setLimitStats({
        allowedLimit,
        currentPeriodUsage: Math.ceil(Number(currentPeriodUsage)),
        remainingTime: Math.ceil(
          Number(allowedLimit) - Number(currentPeriodUsage)
        ),
      })
      setIsPageLoading(false)
    } catch (error) {
      setLimitStats({
        allowedLimit: 0,
        currentPeriodUsage: 0,
        remainingTime: 0,
      })
      setIsPageLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchLimits()
  }, [fetchLimits])

  const exportAsWord = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({ text: 'General Summary' }),
            new Paragraph({ text: results.general }),
            new Paragraph({ text: 'Meeting Minutes' }),
            new Paragraph({ text: results.minutes }),
            new Paragraph({ text: 'Simple Summary' }),
            new Paragraph({ text: results.simple }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => saveAs(blob, 'meeting_summary.docx'))
  }

  const exportAsPDF = () => {
    const doc = new jsPDF()
    doc.text('General Summary', 10, 10)
    doc.text(results.general, 10, 20)
    doc.text('Meeting Minutes', 10, 100)
    doc.text(results.minutes, 10, 110)
    doc.text('Simple Summary', 10, 200)
    doc.text(results.simple, 10, 210)
    doc.save('meeting_summary.pdf')
  }

  const sendEmail = () => {
    const subject = encodeURIComponent('Meeting Summary')
    const body = encodeURIComponent(
      `General Summary:\n${results.general}\n\nMeeting Minutes:\n${results.minutes}\n\nSimple Summary:\n${results.simple}`
    )
    window.location.href = `mailto:?subject=${subject}&body=${body}`
  }

  const copyToClipboard = () => {
    const text = `General Summary:\n${results.general}\n\nMeeting Minutes:\n${results.minutes}\n\nSimple Summary:\n${results.simple}`
    navigator.clipboard
      .writeText(text)
      .then(() => alert('Copied to clipboard!'))
  }

  if (isPageLoading) {
    return (
      <main className='py-20 flex items-center justify-center'>
        <div className='loading-spinner'></div>
      </main>
    )
  }

  return (
    <PageLayout userName={user?.name}>
      <>
        <div className='max-w-6xl mx-auto px-4 py-8'>
          <h1 className='text-3xl font-bold mb-6'>
            Upload and Transcribe Audio
          </h1>

          {!summariesGenerated ? (
            <>
              {isTranscribing ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className='space-y-6'>
                    <div className='bg-white p-6 rounded-lg shadow-md'>
                      <UploadLanguageSelector
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                      />
                      {status?.type === 'error' && (
                        <div className='bg-red-100 border-red-200 border p-4 rounded-lg flex items-center space-x-3'>
                          <LuAlertCircle className='text-red-500 flex-shrink-0' />

                          <p className='text-red-700'>
                            {status.message ||
                              'An error occurred while transcribing, please try again later'}
                          </p>
                        </div>
                      )}
                      <div
                        {...getRootProps()}
                        className={`mt-4 border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
                  ${
                    isDragActive
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-300 hover:border-gray-400'
                  }
                  ${
                    isTranscribing || limitStats.remainingTime < 1
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }
                `}
                      >
                        <input {...getInputProps()} />
                        <LuFileAudio className='w-12 h-12 mx-auto mb-4 text-gray-400' />
                        {isDragActive ? (
                          <p className='text-blue-500'>
                            Drop the audio file here...
                          </p>
                        ) : (
                          <div className='space-y-2'>
                            <p className='text-gray-600'>
                              Drag & drop an audio file here, or click to select
                            </p>
                            <p className='text-sm text-gray-500'>
                              Supported formats: MP3, WAV, OGG, M4A, AAC, WMA,
                              FLAC, AIFF, ALAC, WebM, Opus (max 500MB)
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {status?.type && (
                <div
                  className={`${
                    status.type === 'error'
                      ? 'bg-red-100 border-red-200'
                      : 'bg-green-100 border-green-200'
                  } border p-4 rounded-lg flex items-center space-x-3`}
                >
                  {status.type === 'error' ? (
                    <LuAlertCircle className='text-red-500 flex-shrink-0' />
                  ) : (
                    <LuCheckCircle className='text-green-500 flex-shrink-0' />
                  )}
                  <p
                    className={
                      status.type === 'error'
                        ? 'text-red-700'
                        : 'text-green-700'
                    }
                  >
                    {status.message ||
                      (status.type === 'error'
                        ? status.message
                        : 'Transcription completed successfully!')}
                  </p>
                </div>
              )}
              {status.type !== 'error' && (
                <>
                  <UploadSummaryTabs summaries={results} />
                  <div className='flex justify-center space-x-4 my-8'>
                    <button
                      onClick={exportAsWord}
                      className='bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600'
                      title='Export as Word'
                    >
                      <AiOutlineFileWord size={24} />
                    </button>
                    <button
                      onClick={exportAsPDF}
                      className='bg-red-500 text-white p-2 rounded-lg hover:bg-red-600'
                      title='Export as PDF'
                    >
                      <AiOutlineFilePdf size={24} />
                    </button>
                    <button
                      onClick={sendEmail}
                      className='bg-orange-500 text-white p-2 rounded-lg hover:bg-orange-600'
                      title='Email'
                    >
                      <AiOutlineMail size={24} />
                    </button>
                    <button
                      onClick={copyToClipboard}
                      className='bg-green-500 text-white p-2 rounded-lg hover:bg-green-600'
                      title='Copy'
                    >
                      <AiOutlineCopy size={24} />
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    </PageLayout>
  )
}

export default UploadAudio
