import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import PlanCard from '../components/PlanCard'
import Modal from '../components/Modal'
import PageLayout from '../components/PageLayout'
import { FaPaypal, FaStripe, FaTimes } from 'react-icons/fa'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const PlanSelection = () => {
  const [type, setType] = useState('')
  const [plans, setPlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/plans`
        )
        setPlans(response.data.data)
      } catch (error) {
        console.error('Error fetching plans:', error)
      }
    }

    fetchPlans()
    // fetchStripeKey()
    // eslint-disable-next-line
  }, [])

  const handleSelectPlan = async (plan) => {
    setSelectedPlan(plan) // Update the selected plan state
    setIsModalOpen(true)
  }

  const payWithStripe = async () => {
    setType('stripe')
    const stripe = await stripePromise
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/create-checkout-session`,
        {
          planId: selectedPlan._id,
          paymentMethod: 'stripe',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      const { sessionId } = response.data.data
      setType('')
      const { error } = await stripe.redirectToCheckout({ sessionId })

      if (error) {
        console.error('Stripe error:', error)
        setIsModalOpen(false)
        setSelectedPlan(null)
      }
    } catch (error) {
      setType('')
      console.error('Error creating checkout session:', error)
      alert('Failed to create checkout session. Please try again.')
      setIsModalOpen(false)
      setSelectedPlan(null)
    }
  }
  const payWithPaypal = async () => {
    setType('paypal')
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/create-checkout-session`,
        {
          planId: selectedPlan._id,
          paymentMethod: 'paypal',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      const data = response.data

      setType('')
      window.location.href = data.data.approvalUrl
    } catch (error) {
      setType('')
      console.error('Error creating checkout session:', error)
      alert('Failed to create checkout session. Please try again.')
      setIsModalOpen(false)
      setSelectedPlan(null)
    }
  }

  return (
    <PageLayout>
      <div className='container mx-auto p-4'>
        <h1 className='text-2xl font-bold mb-4'>Select a Plan</h1>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          {plans.map((plan) => (
            <PlanCard key={plan._id} plan={plan} onSelect={handleSelectPlan} />
          ))}
        </div>
      </div>
      {selectedPlan && (
        <Modal isOpen={isModalOpen}>
          <div className='mt-3'>
            <div className='flex justify-between items-center mb-4'>
              <h2 className='text-2xl font-semibold text-gray-800'>
                Proceed to Checkout
              </h2>
              <button
                className='text-red-500 hover:text-red-600'
                onClick={() => {
                  setIsModalOpen(false)
                  setSelectedPlan(null)
                }}
              >
                <FaTimes size={20} />
              </button>
            </div>
            <div className='mt-6'>
              <h3 className='text-lg font-bold text-gray-700'>
                You're about to pay ${selectedPlan.price} for the{' '}
                <span className='text-blue-600'>{selectedPlan.name}</span> plan,
                which includes{' '}
                <span className='text-blue-600'>
                  {convertSecondsToMinutes(
                    selectedPlan.allowedLimit
                  ).toLocaleString()}{' '}
                  minutes
                </span>{' '}
                per month.
              </h3>
              <p className='text-gray-600 mt-2'>
                This amount will be charged monthly.
              </p>
            </div>
            <div className='mt-8'>
              <h4 className='text-lg font-semibold text-gray-800 mb-4'>
                Choose a Payment Method:
              </h4>
              <div className='flex space-x-4'>
                <button
                  className={`w-full ${
                    type === 'stripe' ? 'bg-blue-400' : 'bg-blue-600'
                  }  text-white p-2 rounded-lg ${
                    type !== 'stripe' && 'hover:bg-blue-600'
                  } transition duration-200 flex items-center gap-2`}
                  onClick={payWithStripe}
                  disabled={type === 'stripe'}
                >
                  <span className='text-2xl'>
                    <FaStripe />
                  </span>
                  Pay with Stripe{' '}
                  {type === 'stripe' && <div className='loading'></div>}
                </button>
                <button
                  className={`w-full ${
                    type === 'paypal' ? 'bg-yellow-400' : 'bg-yellow-600'
                  }  text-white p-2 rounded-lg ${
                    type !== 'paypal' && 'hover:bg-yellow-600'
                  } transition duration-200 flex items-center gap-2`}
                  onClick={payWithPaypal}
                  disabled={type === 'paypal'}
                >
                  <span className='text-2xl'>
                    <FaPaypal />
                  </span>
                  Pay with PayPal{' '}
                  {type === 'paypal' && <div className='loading'></div>}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </PageLayout>
  )
}

export default PlanSelection
