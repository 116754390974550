import React from 'react'
import { allLanguages } from '../../utils/constants'
import { Select } from 'antd'

const LanguageSelector = ({ dispatch, selectedLanguages }) => {
  const handleSourceChange = (value) => {
    dispatch({
      type: 'UPDATE_LANGUAGES',
      payload: {
        type: 'source',
        language: value,
      },
    })
  }

  const handleTargetChange = (value) => {
    dispatch({
      type: 'UPDATE_LANGUAGES',
      payload: {
        type: 'target',
        language: value,
      },
    })
  }

  return (
    <div className='flex flex-col space-y-4 w-full max-w-md mx-auto p-4'>
      <div className='space-y-2'>
        <label className='text-gray-700 font-medium block'>
          Source Language (Audio)
        </label>
        <Select
          value={selectedLanguages.source}
          onChange={handleSourceChange}
          className='w-full'
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={allLanguages.map((lang) => ({
            value: lang.code,
            label: lang.name,
          }))}
        />
      </div>

      <div className='space-y-2'>
        <label className='text-gray-700 font-medium block'>
          Target Language (Summary)
        </label>
        <Select
          value={selectedLanguages.target}
          onChange={handleTargetChange}
          className='w-full'
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={allLanguages.map((lang) => ({
            value: lang.code,
            label: lang.name,
          }))}
        />
      </div>
    </div>
  )
}

export default LanguageSelector
