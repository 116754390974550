import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AuthProvider } from './context/AuthContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { Toaster as SonnerToaster } from 'sonner'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
      <SonnerToaster
        position='top-right'
        closeButton={true}
        richColors={true}
        toastOptions={{ classNames: { toast: 'px-4 py-4' } }}
      />
    </Router>
  </React.StrictMode>
)

// DeRegister the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => registration.unregister());
  });
}

