import { useRef, useCallback } from 'react'
import { useRecording } from '../context/RecordingContext'
import { toast } from 'sonner'

export const useMediaRecorder = ({ limitStats }) => {
  const { dispatch } = useRecording()
  const mediaRecorderRef = useRef(null)
  const chunksRef = useRef([])

  const startRecording = useCallback(async () => {
    if (limitStats.remainingTime < 1) {
      console.log(limitStats)

      toast.error(
        ' You have used up your available minutes, please upgrade to continue.'
      )
      return
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const mediaRecorder = new MediaRecorder(stream)
      mediaRecorderRef.current = mediaRecorder

      mediaRecorder.ondataavailable = (event) => {
        chunksRef.current.push(event.data)
      }

      mediaRecorder.start()
      dispatch({ type: 'START_RECORDING' })
    } catch (error) {
      console.error('Failed to start recording:', error)
      throw error
    }
  }, [dispatch, limitStats])

  const stopRecording = useCallback(() => {
    if (!mediaRecorderRef.current) return
    return new Promise((resolve) => {
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/wav' })
        chunksRef.current = []
        dispatch({ type: 'STOP_RECORDING', payload: blob })
        dispatch({ type: 'SET_MODAL', payload: true })
        resolve(blob)
      }

      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop())
    })
  }, [dispatch])

  return { startRecording, stopRecording, mediaRecorderRef }
}
